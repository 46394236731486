<template>
  <div :class="isCollapse?'hide':''">
    <div
      v-for="menu in menuList"
      :key="menu.id"
    >

      <el-submenu
        :index="menu.routerInfo"
        v-if="menu.children"
      >
        <template slot="title">
          <i
            :class="menu.icon"
            v-if="menu.icon"
          ></i>
          <span
            slot="title"
            v-show="!isCollapse"
          >{{menu.name}}</span>
        </template>
        <el-menu-item-group>
          <MenuTree :menuList="menu.children"></MenuTree>
        </el-menu-item-group>

      </el-submenu>

      <el-menu-item
        v-else
        :index="menu.routerInfo"
      >
        <i
          :class="menu.icon"
          v-if="menu.icon"
        ></i>
        <span slot="title">{{menu.name}}</span>
      </el-menu-item>

    </div>
  </div>

</template>

<script>
export default {
  // 组件名称
  name: 'MenuTree',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {
    menuList: {
      require: true
    },
    isCollapse: {
      require: true
    }
  },
  // 组件状态值
  data () {
    return {

    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () { },
  mounted () { },
  // 组件方法
  methods: {}
}
</script>

<style scoped lang="less">
// /deep/.el-menu--collapse {
//   .el-submenu__icon-arrow {
//     display: none;
//   }
// }
.iconfont {
  margin-right: 10px;
  font-size: 20px;
}
.hide {
  /deep/ .el-submenu__icon-arrow {
    display: none;
  }
}
</style>
